.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gradient-border-button {
  position: relative;
  width: 140px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-size: 12px;
  background-color: #DBEAE5;
  border-radius: 15px; /* Закругление 15 пикселей */
  padding: 4px 8px;
  overflow: hidden;
  z-index: 1;
}

.gradient-border-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: inherit;
  background: linear-gradient(160.34deg, #EDBBCD, #C9D0F5);
  background-size: 400% 400%;
  animation: gradientAnimation 3s linear infinite;
  z-index: -1;
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  min-height: 100vh; /* ensures body takes at least full height */
  display: flex;
  flex-direction: column;
  background: linear-gradient(160.34deg, #B2C7DA 14.23%, #BCE3C4 69.69%); /* Ваш градиент */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Если нужны дополнительные стили, можно добавить сюда */
/* .gradient-border-button {
  padding: 10px 20px;
  background: white;
  border-radius: 0.5em;
  font-weight: bold;
  position: relative;
  color: #000;
  font-family: 'Roboto', sans-serif;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
} */

/* .gradient-border-button::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box, 
              linear-gradient(to right, #ff7e5f, #feb47b) border-box;
  border-radius: 0.5em;
  -webkit-mask-composite: destination-out;
  mask-composite: destination-out;
} */
